import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/components/auth/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'eid-password-page',
  templateUrl: './password-page.component.html',
  styleUrls: ['./password-page.component.scss']
})
export class PasswordPageComponent implements OnInit {

  registerError: boolean;
  submitted: boolean;
  hidePassword: boolean;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");
  passwordError = true;

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.hidePassword = true;
    this.registerError = false;
    this.submitted = false;
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordConfirmation = '';
  }

  checkPassword(event){
    this.passwordError = !this.pattern.test(event.target.value);
  }

  validPasswords() {
    if (this.oldPassword!='' && this.newPassword!='' && this.newPasswordConfirmation!='') {
      return false;
    } else {
      return true;
    }
  }

  submitModifications() {
    this.registerError = false;
    if (this.newPassword === this.newPasswordConfirmation) {
      const passwordObj = {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword
      }
      this.submitted = true;
      this.authService.updatePassword(passwordObj).subscribe(res => {
        this.submitted = false;
        this.snackBar.open('Votre mot de passe a bien été modifié !', 'Ok');
      }, error => {
        this.registerError = true;
        this.submitted = false;
        this.snackBar.open('Erreur lors de la modification de votre mot de passe !', 'Ok');
      })
    } else {
      this.registerError = true;
      this.snackBar.open('Attention le nouveau mot de passe et sa confirmation doivent être identiques !', 'Ok');
    }
  }

}
