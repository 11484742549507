import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewerPageComponent } from "./viewer-page.component";
import { ViewerFooterComponent } from './viewer-footer/viewer-footer.component';

import { MatSnackBarModule } from '@angular/material';
import { NgCesiumModule } from "../../ng-cesium/ng-cesium.module";
import { ToolsOverlayModule } from "./tools-overlay/tools-overlay.module";
import { CesiumOverlayModule } from "./cesium-overlay/cesium-overlay.module";
import { MapSettingsOverlayModule } from "./map-settings-overlay/map-settings-overlay.module";

@NgModule({
    declarations: [
        ViewerPageComponent,
        ViewerFooterComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        NgCesiumModule,
        ToolsOverlayModule,
        CesiumOverlayModule,
        MapSettingsOverlayModule,
    ],
    exports: [
        ViewerPageComponent
    ]
})
export class ViewerPageModule {
}
