import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'eid-viewer-page',
    templateUrl: './viewer-page.component.html',
    styleUrls: ['./viewer-page.component.scss']
})
export class ViewerPageComponent implements OnInit {

    public cesiumOptions: Object;
    public cesiumDefaultViewRectangle: Array<number>;

    constructor()
    {
        this.cesiumOptions = {
            sceneMode           : Cesium.SceneMode.SCENE3D,
            fullscreenButton    : false,
            sceneModePicker     : false,
            homeButton          : false,
            baseLayerPicker     : true,
            navigationHelpButton: false,
            geocoder            : true
        };
        this.cesiumDefaultViewRectangle = [ 2.816175, 43.063245, 5.424055, 43.903211 ];
    }

    ngOnInit() {}

}
