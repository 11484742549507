import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { AppConfigService } from 'src/app/config/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, 
                private authService: AuthService, 
                private appConfigService: AppConfigService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        if (this.appConfigService.getAppConfig('visible') || this.authService.logged && this.authService.allowed) {
            return true;
        }
        return new Promise<boolean>((resolve: any, reject: any) => {
            this.authService.isLogged()
                .toPromise()
                .then((logged: boolean) => {
                    if (!logged) {
                        reject(logged);
                        this.authService.redirectUrl = state.url;
                        this.router.navigate([this.appConfigService.getAppConfig('slug'), 'login']);
                        return false;
                    } else {
                        return new Promise<boolean>((resolve: any, reject: any) => {
                            this.authService.isAllowed()
                                .toPromise()
                                .then((allowed: boolean) => {
                                    if (!allowed) {
                                        reject(allowed);
                                        this.router.navigate([this.appConfigService.getAppConfig('slug'), 'not-allowed']);
                                        return false;
                                    } else {
                                        resolve(allowed);
                                        if (this.authService.redirectUrl) {
                                            this.router.navigateByUrl(this.authService.redirectUrl);
                                            this.authService.redirectUrl = null;
                                        }
                                        return true;
                                    }
                                })
                        });
                    }
                })
                .then((res: boolean) => {
                    if (res) {
                        resolve(res);
                    }
                })
        });
    }

}
