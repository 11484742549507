import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface DialogDownloadData {
  dataSource: any;
}

@Component({
  selector: 'optimagri-dialog-download-data',
  templateUrl: './dialog-download-data.component.html',
  styleUrls: ['./dialog-download-data.component.scss']
})
export class DialogDownloadDataComponent implements OnInit {
  public formatList: Array<any>;
  public fileFormat: string;
  public fileName: string;

  constructor(public dialogRef: MatDialogRef<DialogDownloadDataComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDownloadData) {
  }

  ngOnInit() {
    this.formatList = [
      {
        label: 'GeoJSON (.geojson)',
        value: 'geojson'
      }
    ];
    this.fileFormat = 'geojson';
    this.fileName = this.data.dataSource.name;
  }

  downloadFile(): void {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent('Test'));
    element.setAttribute('download', `${this.fileName}.${this.fileFormat}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  close(): void {
    this.dialogRef.close();
  }

}
