import {Component, OnInit} from '@angular/core';
import {CesiumContainerService} from "../../../ng-cesium/cesium-container.service";

@Component({
  selector: 'eid-tools-overlay',
  templateUrl: './tools-overlay.component.html',
  styleUrls: ['./tools-overlay.component.scss']
})
export class ToolsOverlayComponent implements OnInit {
  public measureDistanceHandler: any;
  public displaySettings: boolean;

  constructor(private cesiumContainerService: CesiumContainerService) {
  }

  ngOnInit() {
    this.displaySettings = false;
    this.measureDistanceHandler = {
      flag: false,
      screenSpaceEventHandler: null,
      redLineEntity: null,
      pointEntities: null
    };
  }

  openSettings() {
    this.displaySettings = !this.displaySettings;
    if (!this.displaySettings) {
      this.measureDistanceHandler.flag ? this.toggleMeasureDistance() : false;
    }
  }

  toggleMeasureDistance() {
    this.measureDistanceHandler.flag = !this.measureDistanceHandler.flag;

    this.cesiumContainerService.getViewer('map')
      .then((viewer: any) => {
        if (this.measureDistanceHandler.flag) {
          let points = [];
          this.measureDistanceHandler.pointEntities = [];

          this.measureDistanceHandler.redLineEntity = viewer.entities.add({
            name: 'Red line on the surface',
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArray(points),
              width: 5,
              material: Cesium.Color.RED
            }
          });

          this.measureDistanceHandler.screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

          this.measureDistanceHandler
            .screenSpaceEventHandler
            .setInputAction((movement) => {
              let cartesian = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);
              if (cartesian) {
                let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
                let longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
                let latitudeString = Cesium.Math.toDegrees(cartographic.latitude);

                points.push(longitudeString, latitudeString);

                this.measureDistanceHandler.redLineEntity.polyline.positions = Cesium.Cartesian3.fromDegreesArray(points);

                let geodesic = new Cesium.EllipsoidGeodesic();

                let lengthInMeters = 0;

                for (let i = 0; i < points.length - 2; i += 2) {
                  let startCartographic = Cesium.Cartographic.fromDegrees(points[i], points[i + 1]);

                  let endCartographic = Cesium.Cartographic.fromDegrees(points[i + 2], points[i + 3]);

                  geodesic.setEndPoints(startCartographic, endCartographic);

                  lengthInMeters += Math.round(geodesic.surfaceDistance);
                }

                this.measureDistanceHandler.pointEntities.push(viewer.entities.add({
                    name: 'Point',
                    position: cartesian,
                    point: {
                      pixelSize: 10,
                      color: Cesium.Color.BLUE,
                      outlineColor: Cesium.Color.GRAY,
                      outlineWidth: 2
                    },
                    label: {
                      text: (lengthInMeters / 1000).toFixed(1) + ' km',
                      font: '20px sans-serif',
                      showBackground: true,
                      pixelOffset: new Cesium.Cartesian2(0.0, 20)
                    }
                  })
                );
              }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        } else {
          this.measureDistanceHandler.screenSpaceEventHandler.destroy();
          this.measureDistanceHandler.screenSpaceEventHandler = null;
          viewer.entities.remove(this.measureDistanceHandler.redLineEntity);
          this.measureDistanceHandler.pointEntities.forEach((entity) => {
            viewer.entities.remove(entity);
          });
          this.measureDistanceHandler.redLineEntity = null;
          this.measureDistanceHandler.pointEntities = null;
        }
      });
  }

  print() {
    window.print();
  }

}
