import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { AppConfigService } from 'src/app/config/app-config.service';

@Component({
    selector: 'eid-not-allowed-page',
    templateUrl: './not-allowed-page.component.html',
    styleUrls: ['./not-allowed-page.component.scss']
})
export class NotAllowedPageComponent implements OnInit {

    constructor(private router: Router,
                private authService: AuthService,
                private appConfigService: AppConfigService) {}

    ngOnInit() {
        if (!this.authService.userAccount) {
            this.authService.isLogged()
                .subscribe((logged: boolean) => {
                    if (logged) {
                        this.authService.isAllowed()
                            .subscribe((allowed: boolean) => {
                                if (allowed) {
                                    this.router.navigate([this.appConfigService.getAppConfig('slug')]);
                                }
                        });
                    }
            });
        }
    }

}
