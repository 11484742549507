import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'eid-layers-manager',
    templateUrl: './layers-manager.component.html',
    styleUrls: ['./layers-manager.component.scss']
})
export class LayersManagerComponent implements OnInit {

    @Output() closePanel = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {

    }

    closeCurrentPanel(): void {
        this.closePanel.emit();
    }

}
