import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import { DialogAuthComponent } from './dialog-auth/dialog-auth.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { 
    MatToolbarModule, 
    MatIconModule, 
    MatTooltipModule, 
    MatButtonModule, 
    MatDialogModule,
    MatMenuModule
} from '@angular/material';
import { AuthModule } from '../auth/auth.module';

@NgModule({
    declarations: [
        HeaderComponent,
        DialogAuthComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        MatMenuModule,
        AuthModule
    ],
    entryComponents: [
        DialogAuthComponent
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }
