import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule, MatToolbarModule, MatButtonModule } from '@angular/material';

import { FrontDataComponent } from './front-data.component';

@NgModule({
    declarations: [
        FrontDataComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule
    ],
    exports: [
        FrontDataComponent
    ]
})
export class FrontDataModule { }
