import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { AppConfigService } from 'src/app/config/app-config.service';

@Component({
    selector: 'eid-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

    constructor(private router: Router,
                private authService: AuthService,
                private appConfigService: AppConfigService) {}

    ngOnInit() {
        this.authService.isLogged()
            .subscribe((logged: boolean) => {
                if (logged) { this.router.navigate([this.appConfigService.getAppConfig('slug')]) }
            });
    }

}
