import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule, MatIconModule } from "@angular/material";
import { MatRippleModule } from "@angular/material/core";

import { LayersManagerModule } from "./layers-manager/layers-manager.module";
import { AddDataModule } from "./add-data/add-data.module";
import { DataLibraryModule } from './data-library/data-library.module';
import { FrontDataModule } from './front-data/front-data.module';

import { LeftPanelComponent } from './left-panel.component';

@NgModule({
    declarations: [
        LeftPanelComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatRippleModule,
        LayersManagerModule,
        DataLibraryModule,
        AddDataModule,
        FrontDataModule
    ],
    exports: [
        LeftPanelComponent
    ]
})
export class LeftPanelModule {
}
