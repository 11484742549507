import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { AuthService } from './auth.service';
import { AppConfigService } from 'src/app/config/app-config.service';

import { IUserAccount } from './user-account.model';
import { IUserCredentials } from "./user-credentials.model";

@Component({
    selector: 'eid-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

    @Input() formType: string;
    @Output() submissionSuccess: EventEmitter<boolean>;

    submitted: boolean;
    loginError: boolean;
    registerError: boolean;
    userAlreadyExists: boolean;
    hidePassword: boolean;
    userAccount: IUserAccount;
    userCredentials: IUserCredentials;
    pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");
    passwordError = true;

    constructor(private router: Router,
                private snackBar: MatSnackBar,
                private authService: AuthService,
                private appConfigService: AppConfigService)
    {
        this.submissionSuccess = new EventEmitter<boolean>();
        this.userCredentials = {};
        this.userAccount = {};
        this.hidePassword = true;
        this.submitted = false;
        this.loginError = false;
        this.registerError = false;
        this.userAlreadyExists = false;
    }

    ngOnInit() {
    }

    checkPassword(event){
      this.passwordError = !this.pattern.test(event.target.value);
    }

    login(): void {
        this.submitted = true;
        this.authService.login(this.userCredentials)
            .subscribe(() => {
                this.submissionSuccess.emit(true);
                this.submitted = false;
                this.loginError = false;
                this.authService.isLogged().subscribe();
                this.router.navigate([this.appConfigService.getAppConfig('slug')]);
                }, () => {
                    this.submissionSuccess.emit(false);
                    this.submitted = false;
                    this.loginError = true;
                });
    }

    register(): void {
        this.userAlreadyExists = false;
        this.registerError = false;
        this.submitted = true;
        this.userAccount.login = this.userAccount.email;
        this.userAccount.langKey = "fr";
        this.authService.register(this.userAccount)
            .subscribe(
                () => {
                    this.submissionSuccess.emit(true);
                    this.submitted = false;
                    this.registerError = false;
                    this.snackBar.open('Votre compte a bien été enregistré !', 'Ok');
                },
                (error) => {
                    this.submissionSuccess.emit(false);
                    this.submitted = false;
                    if (error.error.errorKey==="userexists" || error.error.errorKey==="emailexists") {
                        this.userAlreadyExists = true;
                    } else {
                        this.registerError = true;
                    }
                }
            );
    }

}
