import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { 
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatTooltipModule,
    MatChipsModule,
    MatDialogModule,
} from '@angular/material';

import { DataLibraryComponent } from './data-library.component';
import { DialogMetadataModule } from './dialog-metadata/dialog-metadata.module';

import { CookieService } from 'ngx-cookie-service';
import { SearchBarModule } from './search-bar/search-bar.module';

@NgModule({
    declarations: [
        DataLibraryComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatTreeModule,
        MatTooltipModule,
        MatChipsModule,
        MatDialogModule,
        DialogMetadataModule,
        SearchBarModule
    ],
    providers: [
        CookieService
    ],
    exports: [
        DataLibraryComponent
    ]
})
export class DataLibraryModule { }
