import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'eid-dialog-auth',
    templateUrl: './dialog-auth.component.html',
    styleUrls: ['./dialog-auth.component.scss']
})
export class DialogAuthComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogAuthComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
    }

    onSubmissionSuccess(isSuccess: boolean): void {
        if (isSuccess) {
            this.onNoClick();
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
