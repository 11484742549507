import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'eid-viewer-footer',
    templateUrl: './viewer-footer.component.html',
    styleUrls: ['./viewer-footer.component.scss']
})
export class ViewerFooterComponent implements OnInit {

    currentYear: Date = new Date();

    constructor() { }

    ngOnInit() {
    }

}
