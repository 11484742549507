import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface DialogConfirmData {
  matIcon?: string;
  title: string;
  message: string;
}

@Component({
  selector: 'optimagri-dialog-delete-confirm',
  templateUrl: './dialog-delete-confirm.component.html',
  styleUrls: ['./dialog-delete-confirm.component.scss']
})
export class DialogDeleteConfirmComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogDeleteConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData) {
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

}
