import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../../app-routing.module';

import { HomePageModule } from './home-page/home-page.module';
import { ViewerPageModule } from "./viewer-page/viewer-page.module";
import { LoginPageModule } from './login-page/login-page.module';
import { NotAllowedPageModule } from './not-allowed-page/not-allowed-page.module';

import { MainComponent } from './main.component';
import { ProfilPageComponent } from './account-pages/profil-page/profil-page.component';
import { PasswordPageComponent } from './account-pages/password-page/password-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatProgressBarModule, MatSnackBarModule } from '@angular/material';

@NgModule({
    declarations: [
        MainComponent,
        ProfilPageComponent,
        PasswordPageComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        HomePageModule,
        ViewerPageModule,
        LoginPageModule,
        NotAllowedPageModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule, 
        MatIconModule, 
        MatInputModule, 
        MatButtonModule, 
        MatProgressBarModule, 
        MatSnackBarModule 
    ],
    exports: [
        MainComponent
    ]
})
export class MainModule {
}
