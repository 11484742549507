import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Directive({
  selector: '[eidLayerOpacity]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LayerOpacityDirective),
    multi: true
  }]
})
export class LayerOpacityDirective implements ControlValueAccessor {

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  onChangeCallback = (value: any) => {
  };

  // Model To View
  writeValue(value: any): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value * 100);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
  }

  // View To Model
  @HostListener('input', ['$event.target.value']) input(value: any) {
    this.onChangeCallback(value / 100);
  }

}
