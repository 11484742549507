import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule } from '@angular/material';

import { ResourcePreviewMetadataModule } from '../resource-preview-metadata/resource-preview-metadata.module';
import { DialogMetadataComponent } from './dialog-metadata.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

@NgModule({
    declarations: [
        DialogMetadataComponent
    ],
    entryComponents: [
        DialogMetadataComponent
    ],
    imports: [
        CommonModule,
        ResourcePreviewMetadataModule,
        MatButtonModule,
        MatIconModule,
        PDFExportModule
    ],
    exports: [
        DialogMetadataComponent
    ]
})
export class DialogMetadataModule { }
