import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators';
import { ElasticSearchService } from '../elasticsearch.service';
import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';
import { BucketGroup } from '../bucket-group.model';

@Component({
    selector: 'eid-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

    @Input() query: string;
    @Input() bucketGroups: Array<BucketGroup> = [];
    @Output() resultsChanged: EventEmitter<any> = new EventEmitter();

    isSearching: boolean;
    isMapSearchActive: boolean;
    facetsControl: FormControl;
    keywordSearchInput: FormControl;

    private _keywordSearchInputSubscr: Subscription;
    private _cesiumViewer: any;
    private _cesiumCameraEvent: any;

    constructor(private elasticSearchService: ElasticSearchService,
                private cesiumContainer: CesiumContainerService) {
        this.facetsControl = new FormControl('');
        this.keywordSearchInput = new FormControl();
        this.isMapSearchActive = false;
    }

    ngOnInit() {
        this.cesiumContainer.getViewer('map')
            .then((viewer) => {
                this._cesiumViewer = viewer;
                this._cesiumCameraEvent = viewer.scene.camera.moveEnd;
            });

        this._keywordSearchInputSubscr = this.keywordSearchInput.valueChanges
            .pipe(debounceTime(400))
            .subscribe((queryString: any) => {
                this.elasticSearchService.getQueryStringResults(queryString)
                    .subscribe((results: any) => {
                        this.resultsChanged.emit(results.hits);
                    });
            });
        if (this.query) {
            this.keywordSearchInput.setValue(this.query);
        }
    }
    
    ngOnDestroy() {
        this._cesiumCameraEvent.removeEventListener(this.cesiumCameraEventListener, this);
        this._keywordSearchInputSubscr.unsubscribe();
    }

    @ViewChild("searchInput") keywordSearchInputElement: ElementRef;
    searchInputFocus(): void {
        this.keywordSearchInputElement.nativeElement.focus();
    }

    searchInputClearing(): void {
        this.keywordSearchInput.setValue('');
    }

    facetsControlChanged(event: any): void {
        this.elasticSearchService.getFacetResults(event.value)
            .subscribe((results: any) => {
                this.resultsChanged.emit(results.hits);
            });
    }

    searchMapSwitch(): void {
        this.isMapSearchActive = !this.isMapSearchActive;
        if (this.isMapSearchActive) {
            this._cesiumCameraEvent.addEventListener(this.cesiumCameraEventListener, this);
            this.getResultsByBbox(this.getViewRectangle(this._cesiumViewer));
        } else {
            this._cesiumCameraEvent.removeEventListener(this.cesiumCameraEventListener, this);
            this.getResultsByBbox({ east: 180, north: 90, south: -90, west: -180 });
        }
    }

    private cesiumCameraEventListener = () => {
        this.getResultsByBbox(this.getViewRectangle(this._cesiumViewer));
    }

    private getResultsByBbox(viewRectangle: any): void {
        this.elasticSearchService.getBboxFilterResults(viewRectangle)
            .subscribe((results) => {
                this.resultsChanged.emit(results.hits);
            });
    }

    private getViewRectangle(viewer: any): any {
        // NOTE : this function fix a bug on cesium computeViewRectangle() function
        // that return undefined in some cases in 2D mode.
        // See : https://github.com/AnalyticalGraphicsInc/cesium/issues/4346

        let viewRectangle = viewer.camera.computeViewRectangle(viewer.scene.globe.ellipsoid);

        if (viewRectangle === undefined) {
            let cl2 = new Cesium.Cartesian2(0, 0);
            let leftTop = viewer.scene.camera.pickEllipsoid(cl2, viewer.scene.globe.ellipsoid);

            let cr2 = new Cesium.Cartesian2(viewer.scene.canvas.width, viewer.scene.canvas.height);
            let rightDown = viewer.scene.camera.pickEllipsoid(cr2, (viewer.scene.globe.ellipsoid));
            
            leftTop = viewer.scene.globe.ellipsoid.cartesianToCartographic(leftTop);
            rightDown = viewer.scene.globe.ellipsoid.cartesianToCartographic(rightDown);
            viewRectangle = new Cesium.Rectangle(leftTop.longitude, rightDown.latitude, rightDown.longitude, leftTop.latitude);
        }

        return this.convertViewRectangle(viewRectangle);
    }

    private convertViewRectangle(viewRectangle: any): any {
        return {
            west: Cesium.Math.toDegrees(viewRectangle.west),
            south: Cesium.Math.toDegrees(viewRectangle.south),
            east: Cesium.Math.toDegrees(viewRectangle.east),
            north: Cesium.Math.toDegrees(viewRectangle.north)
        }
    }

}
