import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageComponent } from './components/main/home-page/home-page.component';
import { ViewerPageComponent } from "./components/main/viewer-page/viewer-page.component";
import { LoginPageComponent } from './components/main/login-page/login-page.component';
import { NotAllowedPageComponent } from './components/main/not-allowed-page/not-allowed-page.component';

import { AuthGuard } from './components/auth/auth.guard';
import { ProfilPageComponent } from './components/main/account-pages/profil-page/profil-page.component';
import { PasswordPageComponent } from './components/main/account-pages/password-page/password-page.component';

const appRoutes: Routes = [
    { path: ':slug',              component: HomePageComponent,         canActivate: [AuthGuard] },
    { path: ':slug/carte',        component: ViewerPageComponent,       canActivate: [AuthGuard] },
    { path: ':slug/login',        component: LoginPageComponent },
    { path: ':slug/profil',       component: ProfilPageComponent,       canActivate: [AuthGuard]},
    { path: ':slug/password',     component: PasswordPageComponent,     canActivate: [AuthGuard]},
    { path: ':slug/not-allowed',  component: NotAllowedPageComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

