import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {
  MatCardModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatToolbarModule,
  MatTabsModule,
  MatExpansionModule,
  MatSliderModule,
  MatDialogModule, MatTooltipModule, MatDividerModule, MatSelectModule
} from '@angular/material';

import {LayersManagerComponent} from './layers-manager.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DialogDeleteConfirmComponent} from './dialog-delete-confirm/dialog-delete-confirm.component';
import {ImageryLayersListComponent} from './imagery-layer-list/imagery-layers-list.component';
import {DataSourceListComponent} from './data-source-list/data-source-list.component';
import {DialogDownloadDataComponent} from './dialog-download-data/dialog-download-data.component';
import {LayerOpacityDirective} from "../layer-opacity.directive";
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatSliderModule,
    MatDialogModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatDividerModule,
    MatSelectModule,
    DragDropModule
  ],
  declarations: [
    LayersManagerComponent,
    DialogDeleteConfirmComponent,
    ImageryLayersListComponent,
    DataSourceListComponent,
    DialogDownloadDataComponent,
    LayerOpacityDirective
  ],
  exports: [LayersManagerComponent],
  entryComponents: [
    DialogDeleteConfirmComponent,
    DialogDownloadDataComponent
  ]
})
export class LayersManagerModule {
}
