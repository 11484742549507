import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigService } from 'src/app/config/app-config.service';
import { ESQueryModel } from "./elasticsearch-query.model";
import { map } from 'rxjs/internal/operators';

interface CesiumViewRectangle {
    east: number
    north: number
    south: number
    west: number
}

interface FacetFilter {
    term: {
        [key: string]: any
    }
}

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchService {

    private _httpOptions: any;
    private _serverUrl: string;
    private _query: ESQueryModel;

    constructor(private http: HttpClient,
                private appConfigService: AppConfigService)
    {
        this._httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this._serverUrl = this.appConfigService.getEnvConfig('elasticSearchServer');
        this._query = {
            from: 0,
            size: 0,
            query: {
                bool: {
                    must: [
                        { query_string: { default_operator: "AND", query: "*" } }
                    ],
                    filter: {
                        bool: {
                            must: [
                                {
                                    term: { objectType_sort: "MD_Metadata" },
                                },
                                {
                                    ogc_filter: { geoextent: { filter: "BBOX", minx: -179.9, maxx: 179.9, miny: -89.9, maxy: 89.9, CRS: "CRS:84" } }
                                }
                            ],
                            should: []
                        }
                    }                    
                }
            },
            aggs: {
                Year_sort: { terms: { field: "Year_sort", size: 0 } },
                Departement_sort: { terms: { field: "Departement_sort", size: 0 } }
            }
        };
    }

    initServiceState(from: number, size: number) {
        this.setQueryFrom(from);
        this.setQuerySize(size);
        this.setQueryBbox({ east: 180, north: 90, south: -90, west: -180 });
    }

    resetServiceState(): void {
        this.setQueryString("");
        this._query.query.bool.filter.bool.should = [];
    }

    getAllResults(): Observable<any> {
        return this.http.post<any>(`${this._serverUrl}/default/metadata/_search`, this._query, this._httpOptions);
    }

    getQueryStringResults(queryString: string): Observable<any> {
        this.setQueryFrom(0);
        this.setQueryString(queryString);

        return this.getAllResults();
    }

    getBboxFilterResults(bbox: CesiumViewRectangle): Observable<any> {
        this.setQueryFrom(0);
        this.setQueryBbox(bbox);

        return this.getAllResults();
    }

    getFacetResults(facets: Array<string>): Observable<any> {
        this.setQueryFrom(0);
        this.setFacetsResults(facets);

        return this.getAllResults();
    }

    getFrontData(): Observable<any> {
        const url = this.appConfigService.getEnvConfig("examindServer") + "/API/resources/list";
        const body = this.appConfigService.getAppConfig("frontMetadata");
        const facets: Array<string> = [];

        return new Observable(sub => {
            this.http
                .post<Array<number>>(url, body)
                .subscribe((resources: Array<any>) => {
                    resources.forEach((resource: any) => {
                        facets.push(`Identifier.${resource.fileIdentifier}`);
                    });
                    this.getFacetResults(facets)
                        .subscribe((results: any) => {
                            sub.next(results.hits.hits);
                            sub.complete();
                        });
                });
        });
    }

    private setFacetsResults(facets: Array<string>): void {
        this._query.query.bool.filter.bool.should = [];
        facets.forEach((element) => {
            const facet = element.split('.');
            const filter: FacetFilter = {
                "term": {
                    [facet[0]]: facet[1]
                }
            };
            this._query.query.bool.filter.bool.should.push(filter);
        });
    }

    increaseQueryFrom(): void {
        this._query.from += this._query.size;
    }

    private setQueryBbox(cesiumViewRectangle: CesiumViewRectangle): void {
        // Note :   This function covers issues in backend scope because of
        //          its receive only double type. Should be fixed.
        if (cesiumViewRectangle === undefined)
            return
        for (let prop in cesiumViewRectangle) {
            if (cesiumViewRectangle[prop] % 2 === 0) {
                if (cesiumViewRectangle[prop] < 0) {
                    cesiumViewRectangle[prop] += 0.1;
                } else {
                    cesiumViewRectangle[prop] -= 0.1;
                }
            }
        }
        this._query.query.bool.filter.bool.must[1].ogc_filter.geoextent.minx = cesiumViewRectangle.west;
        this._query.query.bool.filter.bool.must[1].ogc_filter.geoextent.maxx = cesiumViewRectangle.east;
        this._query.query.bool.filter.bool.must[1].ogc_filter.geoextent.miny = cesiumViewRectangle.south;
        this._query.query.bool.filter.bool.must[1].ogc_filter.geoextent.maxy = cesiumViewRectangle.north;
    }

    private setQueryFrom(index: number): void {
        if (index >= 0)
            this._query.from = index;
    }

    private setQuerySize(size: number): void {
        if (size >= 0)
            this._query.size = size;
    }

    private setQueryString(queryString: string): void {
        if (queryString === undefined || queryString === "") {
            this._query.query.bool.must[0].query_string.query = `*`;
        } else {
            this._query.query.bool.must[0].query_string.query = `*${ queryString }*`;
        }
    }

    getQueryString(): string {
        return this._query.query.bool.must[0].query_string.query;
    }

    getQuery(): ESQueryModel {
        return this._query;
    }

}
