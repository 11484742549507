import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule, MatButtonModule, MatCardModule } from '@angular/material';

import { HomePageComponent } from "./home-page.component";
import { SearchComponent } from './search/search.component';
import { FrontDataComponent } from './front-data/front-data.component';
import { HomeFooterComponent } from './home-footer/home-footer.component';

@NgModule({
  declarations: [
      HomePageComponent,
      SearchComponent,
      FrontDataComponent,
      HomeFooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule
  ],
  exports: [
      HomePageComponent
  ]
})
export class HomePageModule { }
