import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/components/auth/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'eid-profil-page',
  templateUrl: './profil-page.component.html',
  styleUrls: ['./profil-page.component.scss']
})
export class ProfilPageComponent implements OnInit {

  submitted: boolean;
  registerError: boolean;
  user: any;

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.user = this.authService.userAccount;
    this.registerError = false;
    this.submitted = false;
  }

  submitModifications() {
    this.submitted = true;
    this.authService.updateProfile(this.user).subscribe(res => {
      this.submitted = false;
      this.snackBar.open('Votre compte a bien été modifié !', 'Ok');
    }, error => {
      this.registerError = true;
      this.submitted = false;
      this.snackBar.open('Erreur lors de la modification de votre profil !', 'Ok');
    })
  }

}
