import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/internal/operators';

import { AppConfigService } from 'src/app/config/app-config.service';

import { IGroup } from './group.model';
import { IUserAccount } from './user-account.model';
import { IUserCredentials } from "./user-credentials.model";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _registerServer: string;

    logged = new BehaviorSubject<boolean>(false);
    allowed: boolean;
    redirectUrl: string;
    loggedInterval: number;
    userAccount: IUserAccount;

    constructor(private httpClient: HttpClient,
                private appConfigService: AppConfigService)
    {
        this._registerServer = this.appConfigService.getEnvConfig('registerServer');
        this.allowed = false;
        this.redirectUrl = null;
    }

    login(credentials: IUserCredentials): Observable<any> {
        return this.httpClient.post<any>(`${this._registerServer}/auth/login`, credentials);
    }

    logout(): Observable<any> {
        return this.httpClient.post<any>(`${this._registerServer}/auth/logout`, null);
    }

    register(account: IUserAccount): Observable<any> {
        return this.httpClient.post<any>(`${this._registerServer}/api/register`, account);
    }

    isLogged(): Observable<boolean> {
        return this.httpClient
            .get<IUserAccount>(`${this._registerServer}/api/account`)
            .pipe(
                map((data: IUserAccount) => {
                    this.userAccount = data;
                    this.logged.next(true);
                    return true;
                }),
                catchError(() => {
                    this.userAccount = null;
                    this.logged.next(false);
                    return of(false);
                })
            );
    }

    updateIsLogged(): void {
        this.loggedInterval = window.setInterval(() => {
            this.isLogged().subscribe();
        }, 120000);
    }

    isAllowed(): Observable<boolean> {
        return this.httpClient
            .get<any>(`${this._registerServer}/api/groups/user/${this.userAccount.login}`)
            .pipe(
                map((groups: IGroup[]) => {
                    groups.forEach((group: IGroup) => {
                        if (this.appConfigService.getAppConfig('groups').includes(group.id)) {
                            this.allowed = true;
                        }
                    });
                    return this.allowed;
                }),
                catchError(() => of(false))
            );
    }

    updateProfile(userAccount: IUserAccount) {
        return this.httpClient.post(`${this._registerServer}/api/account`, userAccount);
    }

    updatePassword(passwords: {currentPassword: string, newPassword: string}) {
        return this.httpClient.post(`${this._registerServer}/api/account/change-password`, passwords);
    }

}
