import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'eid-home-footer',
    templateUrl: './home-footer.component.html',
    styleUrls: ['./home-footer.component.scss']
})
export class HomeFooterComponent implements OnInit {

    logo: string;

    constructor() {
        this.logo = './assets/images/logo-eid.png';
    }

    ngOnInit() {
    }

}
