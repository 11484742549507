import { Component, OnInit } from '@angular/core';
import { CesiumContainerService } from "../../../ng-cesium/cesium-container.service";

@Component({
    selector: 'eid-map-settings-overlay',
    templateUrl: './map-settings-overlay.component.html',
    styleUrls: ['./map-settings-overlay.component.scss']
})
export class MapSettingsOverlayComponent implements OnInit {

    cesiumViewer: any;
    scene3DFlag: boolean;

    constructor(private cesiumContainerService: CesiumContainerService) {
    }

    ngOnInit() {
        this.cesiumContainerService.getViewer('map')
            .then((viewer: any) => {
                this.cesiumViewer = viewer;
                this.scene3DFlag = viewer.scene.mode === 2 ? false : true;
            });
    }

    toggle3DMode() {
        this.scene3DFlag = !this.scene3DFlag;
        this.cesiumContainerService.getViewer('map')
            .then((viewer: any) => {
                viewer.scene.mode = this.scene3DFlag ? Cesium.SceneMode.SCENE3D : Cesium.SceneMode.SCENE2D;
            });
    }

    flyToHome() {
        this.cesiumViewer.camera.flyHome();
    }

}
