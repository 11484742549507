import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogAuthComponent } from './dialog-auth/dialog-auth.component';
import { AuthService } from '../auth/auth.service';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'eid-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    private _logo: string;

    appSlug: string;
    appName: string;

    public isLogged: boolean;
    private isLoggedSubscription: Subscription;

    constructor(private dialog: MatDialog,
                private router: Router,
                public authService: AuthService,
                private appConfigservice: AppConfigService)
    { }

    ngOnInit() {
        this._logo = './assets/images/logo-eid.png';
        this.appSlug = this.appConfigservice.getAppConfig('slug');
        this.appName = this.appConfigservice.getAppConfig('name');
        this.authService.isLogged().subscribe();
        this.authService.updateIsLogged();
        this.subscribeToAuthService();
    }

    ngOnDestroy() {
        this.isLoggedSubscription.unsubscribe();
    }

    getLogoUrl(): string {
        return this._logo;
    }

    openAuthDialog(type: string): void {
        let DialogConfig = {};

        if (type === 'login') {
            DialogConfig = {
                width: '450px', height: '450px',
                autoFocus: false, data: { type: 'login', title: 'Connexion' }
            };
        } else if (type === 'register') {
            DialogConfig = {
                width: '550px', height: '620px',
                autoFocus: false, data: { type: 'register', title: 'Inscription' }
            };
        }
        this.dialog.open(DialogAuthComponent, DialogConfig);
    }

    logout(): void {
        this.authService.logout()
            .subscribe(sucess => {
                this.authService.logged.next(false);
                this.router.navigate([this.appConfigservice.getAppConfig('slug')]);
            }, err => {
                this.authService.logged.next(true);
            });
    }

    subscribeToAuthService() {
        this.isLoggedSubscription = this.authService.logged.subscribe(flag => {
            if (flag) {
                this.isLogged = true;
            } else {
                this.isLogged = false;
            }
        })
    }

}
