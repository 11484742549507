import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'eid-add-data',
    templateUrl: './add-data.component.html',
    styleUrls: ['./add-data.component.scss']
})
export class AddDataComponent implements OnInit {

    @Output() closePanel = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    closeCurrentPanel(): void {
        this.closePanel.emit();
    }

}
