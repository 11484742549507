import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { 
    MatButtonModule,
    MatTabsModule, 
    MatIconModule, 
    MatToolbarModule  
} from '@angular/material';

import { ImageryLayersModule } from "./imagery-layers/imagery-layers.module";
import { DataSourceModule } from "./data-source/data-source.module";

import { AddDataComponent } from './add-data.component';

@NgModule({
    declarations: [
        AddDataComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatTabsModule,
        MatIconModule,
        MatToolbarModule,
        ImageryLayersModule,
        DataSourceModule
    ],
    exports: [
        AddDataComponent
    ]
})
export class AddDataModule { }
