import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IAppConfig } from './app-config.model';
import { IEnvConfig } from './env-config.model';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    private _env: string;
    private _envConfig: IEnvConfig;
    private _appConfig: IAppConfig;

    constructor(private httpClient: HttpClient)
    {
        this._env = environment.production ? 'prod' : 'dev';
        this._envConfig = {};
        this._appConfig = {
            slug: window.location.href.split('/')[window.location.href.split('/').indexOf('#') + 1]
        };
        if (this._appConfig.slug == 'https:') {
            this._appConfig.slug = 'eid-mediterranee';
            window.location.href += '#/';
            window.location.href += this._appConfig.slug;
        }
    }

    init(): Promise<any> {
        const configPromise = new Promise<any>((resolve: any, reject: any) => {
            this.httpClient
                .get(`assets/config/config-${this._env}.json`)
                .toPromise()
                .then((response: IEnvConfig) => {
                    this._envConfig = response;
                    return this.getAppConfigPromise();
                })
                .then(() => {
                    resolve();
                })
                .catch((error: any) => {
                    reject(`Could not load file : ${JSON.stringify(error)}`);
                });
        });

        return configPromise;
    }

    private getAppConfigPromise(): Promise<any> {
        return new Promise<any>((resolve: any, reject: any) => {
            this.httpClient
                .get(`${this._envConfig.appManagerServer}/api/apps/slug/${this._appConfig.slug}`)
                .toPromise()
                .then((response: IAppConfig) => {
                    this._appConfig = Object.assign(this._appConfig, response);
                    resolve();
                })
                .catch((error: any) => {
                    reject(`Could not load api : ${JSON.stringify(error)}`);
                });
        });
    }

    getEnvConfig(key?: string): any {
        if (key) {
            return this._envConfig[key];
        }
        return this._envConfig;
    }

    setEnvConfig(key: string, value: any): void {
        if (typeof value === "object") {
            this._envConfig[key] = Object.assign(this._envConfig[key], value);
        } else {
            this._envConfig[key] = value;
        }
    }

    getAppConfig(key?: string): any {
        if (key) {
            return this._appConfig[key];
        }
        return this._appConfig;
    }

    setAppConfig(key: string, value: any): void {
        if (typeof value === "object") {
            this._appConfig[key] = Object.assign(this._appConfig[key], value);
        } else {
            this._appConfig[key] = value;
        }
    }

}
