import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/config/app-config.service';

@Component({
    selector: 'eid-resource-preview-metadata',
    templateUrl: './resource-preview-metadata.component.html',
    styleUrls: ['./resource-preview-metadata.component.scss']
})
export class ResourcePreviewMetadataComponent implements OnInit {

    @Input() resourceId: any;
    public existsMetadata: boolean;
    public metadataValues: Array<any>;

    constructor(private httpClient: HttpClient,
                private translate: TranslateService,
                private appConfigService: AppConfigService)
    {
        const browserLang = this.translate.getBrowserLang();

        this.translate.addLangs(['en', 'fr']);
        this.translate.setDefaultLang('fr');
        this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    }

    ngOnInit() {
        this.existsMetadata = true;
        this.metadataValues = [];
        this.getIsoMetadataJson();
    }

    getIsoMetadataJson() {
        const url = `${this.appConfigService.getEnvConfig('examindServer')}/API/internal/metadata/${this.resourceId}/json`;
        const params = new HttpParams().set('prune', 'true');

        this.httpClient.get(url, { params })
            .subscribe( (response: any) => {
                this.existsMetadata = true;
                if (response && response.root) {
                    this.metadataValues.push(response);
                }
            }, (error) => {
                this.existsMetadata = false;
            });
    }

}
