import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';
import { ElasticSearchService } from '../data-library/elasticsearch.service';
import { DialogMetadataComponent } from '../data-library/dialog-metadata/dialog-metadata.component';
import { ICesiumImageryProviderOptions } from 'src/app/components/ng-cesium/cesium-imagery-provider-options.model';

@Component({
    selector: 'eid-front-data',
    templateUrl: './front-data.component.html',
    styleUrls: ['./front-data.component.scss']
})
export class FrontDataComponent implements OnInit {

    @Output() closePanel = new EventEmitter<string>();

    searchResults: Array<any>;

    constructor(private http: HttpClient,         private snackBar: MatSnackBar,
                private es: ElasticSearchService, private cc: CesiumContainerService,
                public dialog: MatDialog)
    {
        this.searchResults = [];
    }

    ngOnInit() {
        this.es.initServiceState(0, 100);
        this.es.getFrontData()
            .subscribe((results: Array<any>) => {
                this.searchResults = results;
            });
    }

    ngOnDestroy() {
        this.es.resetServiceState();
    }

    getQuickLookImg(result: any): string {
        if (result._source.Quicklook_sort === undefined)
            return './assets/images/no_quicklook_available.png';
        return result._source.Quicklook_sort;
    }

    exportData(result: any): void {
        const downloadLink: string = result._source.DataUrl;
        const filename: string = result._source.Title !== undefined ? result._source.Title_sort : 'exported_data';

        this.http.get(downloadLink, { responseType: "blob" })
            .subscribe(
                (response) => {
                    let blob = new Blob([response], { type: 'application/octet-stream' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');

                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = `${filename}.zip`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    this.snackBar.open('👍 Téléchargement en cours...', 'Ok', {
                        duration: 2000
                    });    
                },
                (error) => {
                    this.snackBar.open('Erreur serveur, impossible de télécharger la ressource', 'Ok');
            });
    }

    showLayer(result: any): void {
        if (this.cc.isMapContext(result)) {
            this.cc.getLayersFromMapContextXML(`${result._source.DataUrl}/raw`)
                .subscribe((layers: ICesiumImageryProviderOptions[]) => {
                    layers.forEach((layer: ICesiumImageryProviderOptions) => {
                        this.cc.addLayer(layer, "map");
                    });
                });
        } else {
            this.cc.getLayerFromResult(result)
                .subscribe((layer: ICesiumImageryProviderOptions) => {
                    this.cc.addLayer(layer, "map");
                });
        }
    }

    canAddLayer(metadataStandardName: string): boolean {
        return metadataStandardName.includes("dataset:doc") ? !metadataStandardName.includes("dataset:doc") : !metadataStandardName.includes("dataset:carte:pdf");
    }

    openMetadataDialog(result: any): void {
        this.dialog.open(DialogMetadataComponent, {
            width: '600px',
            height: '80vh',
            panelClass: 'full-width-dialog',
            autoFocus: false,
            data: {
                result: result._source
            }
        });
    }

    closeCurrentPanel(): void {
        this.closePanel.emit();
    }

}
