import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ICesiumImageryProviderOptions } from './cesium-imagery-provider-options.model';

@Injectable({
    providedIn: 'root'
})
export class CesiumContainerService {

    private _viewerList: any;
    private _layerList: any;

    constructor(private snackBar: MatSnackBar,
                private httpClient: HttpClient)
    {
        this._layerList = {};
        this._viewerList = {};
    }

    getViewer(viewerId: string): Promise<any> {
        return new Promise((resolve) => {
            if (this._viewerList[viewerId]) {
                resolve(this._viewerList[viewerId]);
            } else {
                setTimeout(() => {
                    resolve(this._viewerList[viewerId]);
                }, 1000);
            }
        });
    }

    setViewer(viewerId: string, viewer: any): void {
        this._viewerList[viewerId] = viewer;
    }

    getLayer(layerId: string): ICesiumImageryProviderOptions {
        if (this._layerList[layerId]) {
            return this._layerList[layerId];
        }
        return;
    }

    getLayerList() {
        return this._layerList;
    }

    getLayerListSize(): number {
        return Object.entries(this._layerList).length;
    }

    setLayer(layerId: string, layer: ICesiumImageryProviderOptions): void {
        this._layerList[layerId] = layer;
    }

    isMapContext(data: any): boolean {
        return data._source.MetadataStandardName.includes("ISO:cg2a:dataset:carte");
    }

    getLayerFromResult(data: any): Observable<ICesiumImageryProviderOptions> {
        return new Observable(subscriber => {
            const imageryProviderOptions: ICesiumImageryProviderOptions = {
                url: data._source.WmsUrl,
                layers: data._source.WmsLayer,
                parameters: { version: "1.1.1", transparent: true, format: 'image/png' },
                enablePickFeatures: true,
                rectangle: new Cesium.Rectangle.fromDegrees(data._source.WestBoundLongitude_sort,
                                                            data._source.SouthBoundLatitude_sort,
                                                            data._source.EastBoundLongitude_sort,
                                                            data._source.NorthBoundLatitude_sort),
                proxy: new Cesium.DefaultProxy('/proxy/')
            };

            subscriber.next(imageryProviderOptions);
            subscriber.complete();
        });
    }

    getLayersFromMapContextXML(dataUrl: string): Observable<ICesiumImageryProviderOptions[]> {
        return new Observable(subscriber => {
            this.httpClient
                .get(dataUrl, { responseType: "blob" })
                .subscribe((response: any) => {
                    const blob = new Blob([response], { type: 'application/octet-stream' });
                    const fileUrl = window.URL.createObjectURL(blob);

                    this.httpClient
                        .get(fileUrl, { responseType: "text" })
                        .subscribe((mapContext: string) => {
                            subscriber.next(this.parseMapContext(mapContext));
                            subscriber.complete();
                        });
                });
        });
    }

    private parseMapContext(mapContext: string): ICesiumImageryProviderOptions[] {
        const parser = new DOMParser();
        const xml = parser.parseFromString(mapContext, 'text/xml');
        const entries = xml.getElementsByTagName("ns8:entry");
        const imageryProviders: ICesiumImageryProviderOptions[] = [];

        for (let i = 0; i < entries.length; i++) {
            const operations = entries[i].lastElementChild.getElementsByTagName("owc:operation");

            for (let j = 0; j < operations.length; j++) {
                const code = operations[j].getAttribute("code");

                if (code === "GetMap") {
                    const params: any = this.getParamsFromWmsUrl(operations[j].getAttribute("href"));                    
                    const imageryProviderOptions: ICesiumImageryProviderOptions = {
                        url: params.WMSURL,
                        layers: params.LAYERS,
                        parameters: { version: params.VERSION, transparent: true, format: params.FORMAT },
                        enablePickFeatures: true,
                        proxy: new Cesium.DefaultProxy('/proxy/')
                    };

                    imageryProviders.push(imageryProviderOptions);
                }
            }
        }
        return imageryProviders;
    }

    private getParamsFromWmsUrl(url: string): any {
        const params: any = {};

        url.split('?')[1].split("&").forEach((part: string) => {
            let item = part.split("=");
            params[item[0]] = decodeURIComponent(item[1]);
        });
        params.WMSURL = url.split('?')[0];
        return params;
    }

    addLayer(imageryProviderOptions: ICesiumImageryProviderOptions, viewerId: string): void {
        imageryProviderOptions.getFeatureInfoFormats = [];
        imageryProviderOptions.getFeatureInfoFormats.push(new Cesium.GetFeatureInfoFormat('html'));
        this.getViewer(viewerId)
            .then((viewer: any) => {
                viewer.imageryLayers.addImageryProvider(new Cesium.WebMapServiceImageryProvider(imageryProviderOptions));
                this.snackBar.open('👍 Couches ajoutées', 'Ok', { duration: 5000 });
            });
    }

    deleteLayer(layerId: string): void {
        if (this._layerList[layerId]) {
            delete this._layerList[layerId];
        }
    }

}
