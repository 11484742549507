import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { AuthModule } from '../../auth/auth.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
        LoginPageComponent
    ],
    imports: [
        CommonModule,
        AuthModule,
        FlexLayoutModule
    ],
    exports: [
        LoginPageComponent
    ]
})
export class LoginPageModule { }
