import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonToggleModule, MatIconModule} from "@angular/material";
import {ToolsOverlayComponent} from "./tools-overlay.component";

@NgModule({
  declarations: [ToolsOverlayComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatIconModule
  ],
  exports: [ToolsOverlayComponent]
})
export class ToolsOverlayModule {
}
