import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MatSnackBar, MatDialog } from '@angular/material';
import { DialogMetadataComponent } from './dialog-metadata/dialog-metadata.component';

import { CookieService } from 'ngx-cookie-service';
import { ElasticSearchService } from './elasticsearch.service';
import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';

import { Bucket } from './bucket.model';
import { BucketGroup } from './bucket-group.model';
import { ICesiumImageryProviderOptions } from 'src/app/components/ng-cesium/cesium-imagery-provider-options.model';

@Component({
    selector: 'eid-data-library',
    templateUrl: './data-library.component.html',
    styleUrls: ['./data-library.component.scss']
})
export class DataLibraryComponent implements OnInit, OnDestroy {

    @Input() query: string;
    @Output() closePanel = new EventEmitter<string>();

    bucketGroups: Array<BucketGroup>;
    searchResults: Array<any>;
    searchResultsCount: number;

    private _cookieAccessTokenValue: string;

    constructor(private http: HttpClient,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private es: ElasticSearchService,
                private cc: CesiumContainerService,
                private cookieService: CookieService)
    {
        this.bucketGroups = [];
        this.searchResults = [];
        this.searchResultsCount = 0;
        this._cookieAccessTokenValue = 'UNKNOWN';
    }

    ngOnInit() {
        this.es.initServiceState(0, 4);
        this.es.getAllResults()
            .subscribe((results: any) => {
                this.bucketGroups = this.getBucketGroups(results);
                this.searchResults = results.hits.hits;
                this.searchResultsCount = results.hits.total;
                this.es.increaseQueryFrom();
            });
    }

    private getBucketGroups(results: any): Array<BucketGroup> {
        let tmpBucketGroups: Array<BucketGroup> = [];

        for (let property in results.aggregations) {
            let bucketGroup: BucketGroup = {
                name: property,
                label: null,
                buckets: results.aggregations[property].buckets
            }
            switch (property) {
                case this.es.getQuery().aggs.Departement_sort.terms.field:
                    bucketGroup.label = "Départements";
                    break;
                case this.es.getQuery().aggs.Year_sort.terms.field:
                    bucketGroup.label = "Années";
                    break;
            }
            tmpBucketGroups.unshift(bucketGroup);
        }
        tmpBucketGroups = this.sortBuckets(tmpBucketGroups, 'Départements', 10);

        return tmpBucketGroups;
    }

    private sortBuckets(bucketGroups: Array<BucketGroup>, name: string, keep: number): any {
        bucketGroups.forEach((element) => {
            if (element.name === name) {
                element.buckets.sort((elem: Bucket, next: Bucket) => {
                    return next.doc_count - elem.doc_count;
                });
                element.buckets.splice(keep);
            }
        });

        return bucketGroups;
    }

    ngOnDestroy() {
        this.es.resetServiceState();
    }

    loadMoreResults(): void {
        this.es.getAllResults().subscribe((results: any) => {
            this.searchResults = this.searchResults.concat(results.hits.hits);
            this.searchResultsCount = results.hits.total;
            this.es.increaseQueryFrom();
        });
    }

    hasMoreResults(): boolean {
        return this.searchResults.length < this.searchResultsCount;
    }

    getQuickLookImg(result: any): string {
        if (result._source.Quicklook_sort === undefined)
            return './assets/images/no_quicklook_available.png';
        return result._source.Quicklook_sort;
    }

    exportData(result: any): void {
        const downloadLink: string = result._source.DataUrl;
        const filename: string = result._source.Title !== undefined ? result._source.Title_sort : 'exported_data';

        this._cookieAccessTokenValue = this.cookieService.get('access_token');

        this.http.get(downloadLink, {
            responseType: "blob",
            params: {
                access_token: this._cookieAccessTokenValue
            }
        }).subscribe((response) => {
            let blob = new Blob([response], { type: 'application/octet-stream' });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');

            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `${filename}.zip`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            this.snackBar.open('👍 Téléchargement en cours...', 'Ok', {
                duration: 2000
            });    
        }, (error) => {
            this.snackBar.open('Erreur serveur, impossible de télécharger la ressource', 'Ok');
        });
    }

    showLayer(result: any): void {
        if (this.cc.isMapContext(result)) {
            this.cc.getLayersFromMapContextXML(`${result._source.DataUrl}/raw`)
                .subscribe((layers: ICesiumImageryProviderOptions[]) => {
                    layers.forEach((layer: ICesiumImageryProviderOptions) => {
                        this.cc.addLayer(layer, "map");
                    });
                });
        } else {
            this.cc.getLayerFromResult(result)
                .subscribe((layer: ICesiumImageryProviderOptions) => {
                    this.cc.addLayer(layer, "map");
                });
        }
    }

    canAddLayer(metadataStandardName: string): boolean {
        return metadataStandardName.includes("dataset:doc") ? !metadataStandardName.includes("dataset:doc") : !metadataStandardName.includes("dataset:carte:pdf");
    }

    openMetadataDialog(result: any): void {
        this.dialog.open(DialogMetadataComponent, {
            width: '600px',
            height: '80vh',
            panelClass: 'full-width-dialog',
            autoFocus: false,
            data: {
                result: result._source
            }
        });
    }

    closeCurrentPanel(): void {
        this.closePanel.emit();
    }

    resultsChangedHandler(hits: any): void {
        this.searchResults = hits.hits;
        this.searchResultsCount = hits.total;
        this.es.increaseQueryFrom();
    }

    setDataBlocTypeImage(metadataStandardName: string) {
        const parsedData = metadataStandardName.split(':');
        const dataType = parsedData[parsedData.length-1];
        if (dataType == 'geo-raster') {
            return './assets/images/raster.svg';
        } else if (dataType == 'geo-vector') {
            return './assets/images/vector.svg';
        } else if(dataType == 'carte') {
            return './assets/images/pyramid.svg';
        } else if(dataType == 'doc') {
            return './assets/images/doc.svg';
        } else if(dataType == 'pdf') {
            return './assets/images/pdf.svg';
        } else {
            return './assets/images/doc.svg';
        }
    }

}