import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotAllowedPageComponent } from './not-allowed-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
        NotAllowedPageComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule
    ],
    exports: [
        NotAllowedPageComponent
    ]
})
export class NotAllowedPageModule { }
