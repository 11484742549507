import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'eid-node-render',
    templateUrl: './node-render.component.html',
    styleUrls: ['./node-render.component.scss']
})
export class NodeRenderComponent implements OnInit {

    @Input() jsonObject: any;
    
    constructor() { }

    ngOnInit() { }

    isDateField(render) {
        return (render.toLowerCase().indexOf('date') !== -1 && render.toLowerCase().indexOf('codelist') === -1);
    }

    isCodelistField(render) {
        return (render.toLowerCase().indexOf('codelist') !== -1);
    }

}
