import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'eid-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    private _heroBgImg: string;

    appName: string;
    query: string;

    constructor(private router: Router,
                private appConfigService: AppConfigService)
    {
        this._heroBgImg = './assets/images/hero-bg-2.jpg';
        this.appName = this.appConfigService.getAppConfig('name');
    }
    
    ngOnInit() {
    }

    getHeroBgUrl(): string {
        return this._heroBgImg;
    }

    search(): void {
        const params = {
            queryParams: {
                q: this.query
            }
        };

        this.router.navigate([this.appConfigService.getAppConfig('slug'), 'carte'], params);
    }

}
