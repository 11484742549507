import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CesiumContainerComponent} from './cesium-container.component';
import {CesiumContainerService} from "./cesium-container.service";

@NgModule({
  declarations: [CesiumContainerComponent],
  providers: [CesiumContainerService],
  imports: [
    CommonModule
  ],
  exports: [CesiumContainerComponent]
})
export class NgCesiumModule {
}
