import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/config/app-config.service';

@Component({
    selector: 'eid-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    showFrontDataSection: boolean;

    constructor(private appConfigService: AppConfigService)
    {
        this.showFrontDataSection = this.appConfigService.getAppConfig("withFrontData");
    }

    ngOnInit() {
    }

}
