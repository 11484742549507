import { Component, OnInit } from '@angular/core';

import { CesiumContainerService } from "../../../ng-cesium/cesium-container.service";

@Component({
    selector: 'eid-cesium-overlay',
    templateUrl: './cesium-overlay.component.html',
    styleUrls: ['./cesium-overlay.component.scss']
})
export class CesiumOverlayComponent implements OnInit {

    constructor(private cesiumContainerService: CesiumContainerService) {}

    ngOnInit() {
        this.cesiumContainerService.getViewer('map')
            .then((viewer: any) => {
                // Get the mouse pick position
                const label = {
                    label: {
                        show: false,
                        showBackground: true,
                        font: '14px monospace',
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                        verticalOrigin: Cesium.VerticalOrigin.TOP,
                        pixelOffset: new Cesium.Cartesian2(15, 0)
                    }
                }
                const mousePickEntity = viewer.entities.add(label);
                const screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

                screenSpaceEventHandler.setInputAction(
                    (movement) => {
                        const cartesian = viewer.camera.pickEllipsoid(movement.endPosition, viewer.scene.globe.ellipsoid);

                        if (cartesian) {
                            const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
                            const longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
                            const latitudeString = Cesium.Math.toDegrees(cartographic.latitude);
                            // const heightString = Cesium.Math.toDegrees(cartographic.height);

                            mousePickEntity.position = cartesian;
                            mousePickEntity.label.show = false;
                            mousePickEntity.label.text = 'Lon: ' + ('   ' + longitudeString).slice(-7) + '\u00B0';
                            mousePickEntity.label.text += ' | ';
                            mousePickEntity.label.text += 'Lat: ' + ('   ' + latitudeString).slice(-7) + '\u00B0';
                            // mousePickEntity.label.text += ' | ';
                            // mousePickEntity.label.text += 'Alt: ' + ('   ' + heightString).slice(-7) + '\u00B0';
                            // Add text into the footer to display data
                            document.getElementById("footerMousePickEntity").innerHTML = mousePickEntity.label.text;
                        } else {
                            mousePickEntity.label.show = false;
                        }
                    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
                });
    }

}
