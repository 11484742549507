import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcePreviewMetadataComponent } from './resource-preview-metadata.component';
import { NodeRenderComponent } from './node-render/node-render.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ResourcePreviewMetadataComponent,
        NodeRenderComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [
        ResourcePreviewMetadataComponent
    ]
})
export class ResourcePreviewMetadataModule { }
