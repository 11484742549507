import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfigService } from 'src/app/config/app-config.service';
import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';
import { ElasticSearchService } from '../../viewer-page/cesium-overlay/left-panel/data-library/elasticsearch.service';

import { ICesiumImageryProviderOptions } from 'src/app/components/ng-cesium/cesium-imagery-provider-options.model';

@Component({
    selector: 'eid-front-data',
    templateUrl: './front-data.component.html',
    styleUrls: ['./front-data.component.scss']
})
export class FrontDataComponent implements OnInit {

    start: number;
    end: number;
    frontData: Array<any>;

    constructor(private router: Router,
                private appConfigService: AppConfigService,
                private elasticSearchService: ElasticSearchService,
                private cesiumContainerService: CesiumContainerService)
    {
        this.start = 0;
        this.end = 3;
        this.frontData = [];
    }

    ngOnInit() {
        this.elasticSearchService.initServiceState(0, 100);
        this.elasticSearchService.getFrontData()
            .subscribe((results: Array<any>) => {
                this.frontData = results;
            });
    }

    ngOnDestroy() {
        this.elasticSearchService.resetServiceState();
    }

    getQuickLookImg(data: any): string {
        if (data._source.Quicklook_sort === undefined)
            return './assets/images/no_quicklook_available.png';
        return data._source.Quicklook_sort;
    }

    right(): void {
        if (this.end < this.frontData.length) {
            this.start++;
            this.end++;
        }
    }

    left(): void {
        if (this.start > 0) {
            this.start--;
            this.end--;
        }
    }

    showFrontData(data: any): void {
        if (this.cesiumContainerService.isMapContext(data)) {
            this.cesiumContainerService.getLayersFromMapContextXML(`${data._source.DataUrl}/raw`)
                .subscribe((layers: ICesiumImageryProviderOptions[]) => {
                    layers.forEach((layer: ICesiumImageryProviderOptions) => {
                        this.cesiumContainerService.setLayer(layer.layers, layer);
                        this.router.navigate([this.appConfigService.getAppConfig('slug'), 'carte']);
                    });
                });
        } else {
            this.cesiumContainerService.getLayerFromResult(data)
                .subscribe((layer: ICesiumImageryProviderOptions) => {
                    this.cesiumContainerService.setLayer(layer.layers, layer);
                    this.router.navigate([this.appConfigService.getAppConfig('slug'), 'carte']);
                });
        }
    }

}
