import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapSettingsOverlayComponent} from './map-settings-overlay.component';
import {MatButtonModule, MatIconModule} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
  declarations: [MapSettingsOverlayComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule
  ],
  exports: [MapSettingsOverlayComponent]
})
export class MapSettingsOverlayModule {
}
