import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';

@Component({
    selector: 'eid-imagery-layers-list',
    templateUrl: './imagery-layers-list.component.html',
    styleUrls: ['./imagery-layers-list.component.scss']
})
export class ImageryLayersListComponent implements OnInit {

    private cesiumViewer: any;
    public imageryLayerCollection: Array<any>;

    constructor(private cesiumContainerService: CesiumContainerService) { }

    ngOnInit() {
        this.cesiumViewer = this.cesiumContainerService.getViewer('map')
            .then((viewer) => {
                this.cesiumViewer = viewer;
                this.imageryLayerCollection = Object.assign([], this.cesiumViewer.imageryLayers._layers);
                this.imageryLayerCollection.shift();
                this.imageryLayerCollection.reverse();
        });
    }

    isWMSImageryLayer(imageryLayer: any): boolean {
        if (imageryLayer.imageryProvider._resource.queryParameters.service === 'WMS') {
            return true;
        }
        return false;
    }

    getLayerTitle(imageryLayer: any): string {
        return imageryLayer.imageryProvider._layers || imageryLayer.imageryProvider._layer || '';
    }

    deleteImageryLayer(imageryLayer: any, itemIndex: number): void {
        this.cesiumViewer.imageryLayers.remove(imageryLayer, true);
        this.imageryLayerCollection.splice(itemIndex, 1);
        this.cesiumContainerService.deleteLayer(imageryLayer._imageryProvider._layers);
    }

    zoomToImageryLayer(imageryLayer: any): void {
        this.cesiumViewer.flyTo(imageryLayer);
    }

    moveDownImageryLayer(imageryLayer: any, itemIndex: number): void {
        this.imageryLayerCollection.splice(itemIndex + 1, 0, this.imageryLayerCollection.splice(itemIndex, 1)[0]);
        this.cesiumViewer.imageryLayers.lower(imageryLayer);
    }

    moveUpImageryLayer(imageryLayer: any, itemIndex: number): void {
        this.imageryLayerCollection.splice(itemIndex - 1, 0, this.imageryLayerCollection.splice(itemIndex, 1)[0]);
        this.cesiumViewer.imageryLayers.raise(imageryLayer);
    }

    handleBrokenImg(event: any): boolean {
        event.srcElement.onerror = '';
        event.srcElement.width = 80;
        event.srcElement.src = './assets/images/no_legend_available.png';
        return true;
    }

    dropLayer(event: CdkDragDrop<string[]>): void {
        if (event.currentIndex < event.previousIndex) {
            for (let i = event.currentIndex ; i < event.previousIndex ; i++) {
                this.cesiumViewer.imageryLayers.raise(this.imageryLayerCollection[event.previousIndex]);
            }
        } else if (event.currentIndex > event.previousIndex) {
            for (let i = event.previousIndex ; i < event.currentIndex ; i++) {
                this.cesiumViewer.imageryLayers.lower(this.imageryLayerCollection[event.previousIndex]);
            }
        }
        moveItemInArray(this.imageryLayerCollection, event.previousIndex, event.currentIndex);
    }

}
