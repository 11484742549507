import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { defineFont } from '@progress/kendo-drawing/pdf';

defineFont({
  'DejaVu Sans'             : 'https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf',
  'DejaVu Sans|Bold'        : 'https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf',
  'DejaVu Sans|Bold|Italic' : 'https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf',
  'DejaVu Sans|Italic'      : 'https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf'
});

@Component({
    selector: 'eid-dialog-metadata',
    templateUrl: './dialog-metadata.component.html',
    styleUrls: ['./dialog-metadata.component.scss']
})
export class DialogMetadataComponent implements OnInit {

    constructor(public http: HttpClient,
                public snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<DialogMetadataComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getQuickLookImg(): string {
        if (this.data.result.Quicklook_sort === undefined)
            return './assets/images/no_quicklook_available.png';
        return this.data.result.Quicklook_sort;
    }

    exportData(): void {
        const downloadLink: string = this.data.result.DataUrl;
        const filename: string = this.data.result.Title !== undefined ? this.data.result.Title_sort : 'exported_data';

        this.http.get(downloadLink, { responseType: "blob" })
            .subscribe(
                (response) => {
                    let blob = new Blob([response], { type: 'application/octet-stream' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');

                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = `${filename}.zip`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    this.snackBar.open('👍 Téléchargement en cours...', 'Ok', {
                        duration: 2000
                    });    
                },
                (error) => {
                    this.snackBar.open('Erreur serveur, impossible de télécharger la ressource', 'Ok');
            });
    }

}
