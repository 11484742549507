import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatProgressBarModule, MatSnackBarModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    declarations: [
        AuthComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatProgressBarModule,
        MatSnackBarModule
    ],
    exports: [
        AuthComponent
    ],
    providers: [
        CookieService
    ]
})
export class AuthModule { }
