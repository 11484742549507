import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule, MatTooltipModule, MatFormFieldModule, MatOptionModule, MatSelectModule, MatSlideToggleModule, MatBadgeModule } from '@angular/material';

@NgModule({
    declarations: [
        SearchBarComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatSlideToggleModule,
        MatBadgeModule
    ],
    exports: [
        SearchBarComponent
    ]
})
export class SearchBarModule { }
