import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';
import { ICesiumImageryProviderOptions } from 'src/app/components/ng-cesium/cesium-imagery-provider-options.model';

@Component({
    selector: 'eid-imagery-layers',
    templateUrl: './imagery-layers.component.html',
    styleUrls: ['./imagery-layers.component.scss']
})
export class ImageryLayersComponent implements OnInit {

    private cesiumViewer: any;
    public service: any;
    private secureService: boolean;
    public serviceTypes: Array<object>;
    private credentials: any;
    private getCapabilitiesParams: HttpParams = new HttpParams();
    private wmsLayers: Array<string>;
    public wmsLayersTitle: string;
    public wmtsLayers: Array<string>;
    private wmtsStyles: Array<any>;
    private wmtsMatrixSets: Array<any>;
    private wmtsTileMatrix: Array<any>;
    public layersProgressBar: boolean = false;

    constructor(private httpClient: HttpClient, 
                private cesiumContainerService: CesiumContainerService, 
                private snackBar: MatSnackBar)
    {
        this.cesiumViewer = null;
    }

    ngOnInit() {
        this.cesiumContainerService.getViewer('map')
            .then((viewer) => {
                this.cesiumViewer = viewer;
        });

        this.serviceTypes = [
            { label: 'WMS', value: 'wms' },
            { label: 'WMTS', value: 'wmts' }
        ];

        this.service = {
            type: 'wms',
            url: '',
            wmsLayers: [],
            wmsParams: {
                version: '1.3.0',
                transparent: true,
                format: 'image/png'
            },
            wmtsLayer: '',
            wmtsParams: {
                style: '',
                tileMatrixSetID: ''
            }
        };

        this.credentials = { login: '', password: '' };

        this.getCapabilitiesParams = this.getCapabilitiesParams.set('REQUEST', 'GetCapabilities');

        this.wmsLayers = [];
        this.wmsLayersTitle = '';
        this.wmtsLayers = [];
    }

    getCapabilities(): void {
        this.layersProgressBar = true;

        if (this.service.type === 'wms') {
            this.getCapabilitiesParams = this.getCapabilitiesParams
                .set('SERVICE', 'WMS')
                .set('version', this.service.wmsParams.version);
            this.service.wmsLayers = [];
        } else {
            this.getCapabilitiesParams = this.getCapabilitiesParams
                .set('SERVICE', 'WMTS');
            this.service.wmtsLayer = '';
        }

        this.httpClient.get(this.service.url, {
            responseType: 'text',
            params: this.getCapabilitiesParams
        }).subscribe(response => {
            if (this.service.type === 'wms') {
                let jsonData = new WMSCapabilities().parse(response);
                if (!jsonData.Capability) {
                    this.snackBar.open('Erreur serveur, impossible de récupérer les couches WMS', 'Ok');
                    return;
                }
                // Extract the layers provided by this service
                this.wmsLayers = jsonData.Capability.Layer.Layer;
                this.wmsLayersTitle = jsonData.Service.Title;
            } else {
                let x2js = new X2JS();
                let jsonData = x2js.xml2js(response);

                if (!jsonData.Capabilities) {
                    this.snackBar.open('Erreur serveur, impossible de récupérer les couches WMTS', 'Ok');
                    return;
                }

                this.wmtsTileMatrix = Array.isArray(jsonData.Capabilities.Contents.TileMatrixSet) ?
                    jsonData.Capabilities.Contents.TileMatrixSet :
                    [jsonData.Capabilities.Contents.TileMatrixSet];

                this.wmtsLayers = Array.isArray(jsonData.Capabilities.Contents.Layer) ?
                    jsonData.Capabilities.Contents.Layer :
                    [jsonData.Capabilities.Contents.Layer];

                }

            this.layersProgressBar = false;

        }, error => {
            this.snackBar.open("Erreur, veuillez vérifier l'URL du service", 'Ok');
            this.layersProgressBar = false;
        });
    }

    getWMSRectangle(layer): any {
        let bboxKey = this.service.wmsParams.version === '1.3.0' ? 'EX_GeographicBoundingBox' : 'LatLonBoundingBox';
        return Cesium.Rectangle.intersection(
            Cesium.Rectangle.clone(Cesium.Rectangle.MAX_VALUE),
            layer[bboxKey] ? Cesium.Rectangle.fromDegrees(
                layer[bboxKey][0],
                layer[bboxKey][1],
                layer[bboxKey][2],
                layer[bboxKey][3]
            ) : Cesium.Rectangle.clone(Cesium.Rectangle.MAX_VALUE));
    }

    getWMTSRectangle(): any {
        let lowerCorner = this.service.wmtsLayer.WGS84BoundingBox.LowerCorner.toString().split(' ');
        let upperCorner = this.service.wmtsLayer.WGS84BoundingBox.UpperCorner.toString().split(' ');
        return Cesium.Rectangle.intersection(
            Cesium.Rectangle.clone(Cesium.Rectangle.MAX_VALUE),
            Cesium.Rectangle.fromDegrees(
                +lowerCorner[0],
                +lowerCorner[1],
                +upperCorner[0],
                +upperCorner[1]
            ));
    }

    getTileMatrixLabels(): Array<any> {
        const tileMatrixArray = this.wmtsTileMatrix.filter(item => item.Identifier.toString() === this.service.wmtsParams.tileMatrixSetID);
        const array = tileMatrixArray[0].TileMatrix.map(el => el.Identifier.toString());
        // return array;
        //For Examind
        return array.reverse();
    }

    onChange(event: any, layer: any): void {
        if (event.checked) {
            this.service.wmsLayers.push(layer);
        } else {
            this.service.wmsLayers.splice(this.service.wmsLayers.findIndex( (el) => el.Name === layer.Name), 1);
        }
    }

    loadLayer(): void {
        let serviceProvider;

        if (this.service.type === 'wms') {
            this.service.wmsLayers.forEach(element => {
                const options: ICesiumImageryProviderOptions = {
                    url: this.service.url,
                    layers: element.Name,
                    parameters: this.service.wmsParams,
                    enablePickFeatures: true,
                    rectangle: this.getWMSRectangle(element),
                    proxy: new Cesium.DefaultProxy('/proxy/')
                };
                serviceProvider = new Cesium.WebMapServiceImageryProvider(options); 
                this.cesiumViewer.imageryLayers.addImageryProvider(serviceProvider);
            });
        } else {
            serviceProvider = new Cesium.WebMapTileServiceImageryProvider({
                url: this.service.url,
                format: 'image/png',
                layer: this.service.wmtsLayer.Title.toString(),
                style: this.service.wmtsParams.style,
                // rectangle: this.getWMTSRectangle(),
                tileMatrixSetID: this.service.wmtsParams.tileMatrixSetID,
                tileMatrixLabels: this.getTileMatrixLabels(),
                // tilingScheme: new Cesium.GeographicTilingScheme()
            });
             this.cesiumViewer.imageryLayers.addImageryProvider(serviceProvider);
        }
        this.snackBar.open('👍 Couches ajoutées', 'Ok', {
            duration: 5000
        });
        this.cesiumViewer.camera.flyTo({
            destination: serviceProvider.rectangle
        });
    }

    canAddLayer(): boolean {
        if (this.service.type === 'wms') {
            return this.service.url && this.service.type && (this.service.wmsLayers.length > 0);
        }
        return this.service.url && this.service.type && this.service.wmtsLayer &&
            this.service.wmtsParams.style && this.service.wmtsParams.tileMatrixSetID;
    }

    refreshWMTSLayer(): void {
        this.wmtsStyles = Array.isArray(this.service.wmtsLayer.Style) ?
            this.service.wmtsLayer.Style :
            [this.service.wmtsLayer.Style];

        this.wmtsMatrixSets = Array.isArray(this.service.wmtsLayer.TileMatrixSetLink) ?
            this.service.wmtsLayer.TileMatrixSetLink :
            [this.service.wmtsLayer.TileMatrixSetLink];

        this.service.wmtsParams.style = '';
        this.service.wmtsParams.tileMatrixSetID = '';

    }

    refreshServiceType(): void {
        this.getCapabilitiesParams = new HttpParams().set('REQUEST', 'GetCapabilities');
    }

}
