import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';

import { FormBuilder, FormGroup, Validators } from "@angular/forms";

declare let Cesium;

@Component({
    selector: 'eid-data-source',
    templateUrl: './data-source.component.html',
    styleUrls: ['./data-source.component.scss']
})
export class DataSourceComponent implements OnInit {

    public fileFormatFormGroup: FormGroup;

    private cesiumViewer: any;
    private fileFormat: string;
    public supportedFileFormats: Array<any>;
    public selectedFile: any;
    private displayedColumns: Array<string>;
    private dataSource: any;
    private url3DTileSets: string;
    public displayWarningMessage: boolean;

    @ViewChild(MatSort) sort: MatSort;

    constructor(private snackBar: MatSnackBar,
        private cesiumContainerService: CesiumContainerService,
        private _formBuilder: FormBuilder) { }

    ngOnInit() {
        this.displayWarningMessage = true;
        this.supportedFileFormats = [
            { label: 'GeoJSON', value: 'geojson' },
            { label: 'KML', value: 'kml' }
        ];
        this.fileFormat = this.supportedFileFormats[0].value;

        this.cesiumViewer = this.cesiumContainerService.getViewer('map').then((viewer) => {
            this.cesiumViewer = viewer;
        });

        this.displayedColumns = ['name', 'size'];
        this.url3DTileSets = '';

        this.fileFormatFormGroup = this._formBuilder.group({
            fileFormat: [this.fileFormat, Validators.required],
        });
    }

    onFileChanged(event: any): void {
        this.selectedFile = event.target.files[0];
        this.dataSource = new MatTableDataSource([this.selectedFile]);
        this.dataSource.sort = this.sort;
    }

    onFileDropped(event: any) {
        event.preventDefault();
        this.selectedFile = event.dataTransfer.files[0];
        this.dataSource = new MatTableDataSource([this.selectedFile]);
        this.dataSource.sort = this.sort;
    }

    disabledAddDataBtn(): boolean {
        switch (this.fileFormat) {
            case 'geojson':
            case 'kml':
                return !this.fileFormat || !this.selectedFile;
            case '3dTiles':
                return !this.fileFormat || !this.url3DTileSets;
        }
    }

    addDataFile(): void {
        switch (this.fileFormat) {
            case 'geojson':
                this.addGeoJsonDataSource(this.selectedFile);
                break;
            case 'kml':
                this.addKMLDataSource(this.selectedFile);
                break;
            case '3dTiles':
                this.add3DTileset(this.url3DTileSets);
                break;
        }
    }

    private addGeoJsonDataSource(fileObject): void {
        const fileObjectURL = window.URL.createObjectURL(fileObject);
        const dataSource = Cesium.GeoJsonDataSource.load(fileObjectURL);
        dataSource.then(() => {
            this.cesiumViewer.dataSources.add(dataSource);
            this.cesiumViewer.zoomTo(dataSource);
            this.removeFile();
            this.snackBar.open('👐 Bravo ! Une nouvelle couche vient d\'être ajoutée.', 'Ok', {
                duration: 5000
            });
        }, () => {
            this.snackBar.open('Erreur : format de fichier invalide.', 'Ok');
        });
    }

    private addKMLDataSource(fileObject): void {
        const fileObjectURL = window.URL.createObjectURL(fileObject);
        const dataSource = Cesium.KmlDataSource.load(fileObjectURL);
        dataSource.then(() => {
            this.cesiumViewer.dataSources.add(dataSource);
            this.cesiumViewer.zoomTo(dataSource);
            this.removeFile();
            this.snackBar.open('👐 Bravo ! Une nouvelle couche vient d\'être ajoutée.', 'Ok', {
                duration: 5000
            });
        }, () => {
            this.snackBar.open('Erreur : format de fichier invalide.', 'Ok');
        });

    }

    private add3DTileset(url): void {
        const tileSet = new Cesium.Cesium3DTileset({ url: url });

        tileSet.readyPromise.then((tileset) => {
            this.cesiumViewer.scene.primitives.add(tileset);
            this.cesiumViewer.zoomTo(tileset);
        }, (error) => {
            this.snackBar.open('Error the file does not match the selected format', 'close');
        });
    }

    removeFile() { }

    canUploadFile(): boolean {
        return this.fileFormat !== '3dTiles';
    }

    isFileSelected(): boolean {
        return !this.selectedFile;
    }

    setFileFormat(): void {
        this.fileFormat = this.fileFormatFormGroup.value.fileFormat
    }

    dragOverHandler(event: Event) {
        event.preventDefault();
    }

    radioButtonEvent(formatValue: string) {
        if (formatValue=='geojson') {
            this.displayWarningMessage = true;
        } else {
            this.displayWarningMessage = false;
        }
    }

}
