import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { DialogDeleteConfirmComponent } from '../dialog-delete-confirm/dialog-delete-confirm.component';
import { DialogDownloadDataComponent } from '../dialog-download-data/dialog-download-data.component';
import { CesiumContainerService } from 'src/app/components/ng-cesium/cesium-container.service';

@Component({
    selector: 'eid-data-source-list',
    templateUrl: './data-source-list.component.html',
    styleUrls: ['./data-source-list.component.scss']
})
export class DataSourceListComponent implements OnInit {

    private cesiumViewer: any;
    public dataSourceCollection: Array<any>;

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private cesiumContainerService: CesiumContainerService) { }

    ngOnInit() {
        this.cesiumContainerService.getViewer('map')
            .then((viewer) => {
                this.cesiumViewer = viewer;
                this.dataSourceCollection = Object.assign([], this.cesiumViewer.dataSources._dataSources);
        });
    }

    deleteDataSource(dataSource: any, itemIndex: number): void {
        const confirmDialogRef = this.dialog.open(DialogDeleteConfirmComponent, {
            width: '500px',
            disableClose: false,
            data: {
                matIcon: 'delete',
                title: 'Supprimer la couche de données',
                message: `Êtes-vous sûr(e) de vouloir supprimer la données ${ dataSource.name } ?`
            }
        });
        confirmDialogRef.afterClosed().subscribe((confirm) => {
            if (confirm) {
                this.cesiumViewer.dataSources.remove(dataSource, true);
                this.dataSourceCollection.splice(itemIndex, 1);
                this.snackBar.open('La couche de donnée a bien été supprimée', 'OK', {
                    duration: 2000
                });
            }
        });
    }

    zoomToDataSource(dataSource: any): void {
        this.cesiumViewer.zoomTo(dataSource);
    }

    // Not functional: see DialogDownloadDataComponent
    downloadDataSource(dataSource: any): void {
        this.dialog.open(DialogDownloadDataComponent, {
            width: '500px',
            disableClose: false,
            data: {
                dataSource: dataSource
            }
        });
    }

    // Not functional at all
    // changeDataSourceColor(dataSource: any): void {
    //     console.log("Change color = " + dataSource);
    // }

    moveDownDataSource(dataSource: any, itemIndex: number): void {
        this.dataSourceCollection.splice(itemIndex + 1, 0, this.dataSourceCollection.splice(itemIndex, 1)[0]);
        this.cesiumViewer.dataSources.lower(dataSource);
    }

    moveUpDataSource(dataSource: any, itemIndex: number): void {
        this.dataSourceCollection.splice(itemIndex - 1, 0, this.dataSourceCollection.splice(itemIndex, 1)[0]);
        this.cesiumViewer.dataSources.raise(dataSource);
    }

    dropLayer(event: CdkDragDrop<string[]>): void {
        if (event.currentIndex < event.previousIndex) {
            for (let i = event.currentIndex ; i < event.previousIndex ; i++) {
                this.cesiumViewer.imageryLayers.raise(this.dataSourceCollection[event.previousIndex]);        
            }
        } else if (event.currentIndex > event.previousIndex) {
            for (let i = event.previousIndex ; i < event.currentIndex ; i++) {
                this.cesiumViewer.imageryLayers.lower(this.dataSourceCollection[event.previousIndex]);
            }
        }
        moveItemInArray(this.dataSourceCollection, event.previousIndex, event.currentIndex);
    }

}
