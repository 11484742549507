import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CesiumContainerService } from "src/app/components/ng-cesium/cesium-container.service";
import { AppConfigService } from 'src/app/config/app-config.service';

enum Panels {
    FRONT_DATA = 1,
    DATA_LIBRARY,
    LAYERS_MANAGER,
    ADD_DATA,
}

@Component({
    selector: 'eid-left-panel',
    templateUrl: './left-panel.component.html',
    styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {

    cesiumViewer: any;
    showFrontDataPanel: boolean;
    selectedPanel: Panels;
    query: string;

    constructor(private activatedRoute: ActivatedRoute,
                private appConfigService: AppConfigService,
                private cesiumContainerService: CesiumContainerService)
    {
        this.cesiumViewer = null;
        this.showFrontDataPanel = this.appConfigService.getAppConfig("withFrontData");
        this.activatedRoute.queryParams
            .subscribe(params => {
                this.selectedPanel = params['q'] ? Panels.DATA_LIBRARY : null;
                this.query = params['q'];
            });
    }

    ngOnInit() {
        this.cesiumContainerService.getViewer('map')
            .then((viewer: any) => {
                this.cesiumViewer = viewer;
                if (this.cesiumContainerService.getLayerListSize() > 0) {
                    this.selectedPanel = Panels.LAYERS_MANAGER;
                }
            });
    }

    changePanel(panel) {
        if (panel === this.selectedPanel) {
            this.selectedPanel = null;
        } else {
            this.selectedPanel = panel;
        }
    }

    isSelectedPanel(panel) {
        return panel && panel === this.selectedPanel;
    };

    canShowCatalogManagerPanel(): boolean {
        return this.appConfigService.getAppConfig("withCatalog");
    }

}
