import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";

import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule, MatTableModule, MatStepperModule,
} from '@angular/material';

import { DataSourceComponent } from "./data-source.component";

import { FileSizePipe } from "./file-size.pipe";

@NgModule({
    declarations: [
        DataSourceComponent, 
        FileSizePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatToolbarModule,
        MatSelectModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        FlexLayoutModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatListModule,
        MatProgressBarModule,
        MatTableModule,
        MatStepperModule
    ],
    exports: [
        DataSourceComponent
    ]
})
export class DataSourceModule { }
