import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { AppConfigService } from 'src/app/config/app-config.service';
import { CesiumContainerService } from "./cesium-container.service";

import { ICesiumImageryProviderOptions } from './cesium-imagery-provider-options.model';

@Component({
    selector: 'eid-cesium-container',
    templateUrl: './cesium-container.component.html',
    styleUrls: ['./cesium-container.component.scss']
})
export class CesiumContainerComponent implements OnInit {

    private _defaultOptions: any;

    @Input() options?: any = {};
    @Input() defaultViewRectangle?;
    
    viewer: any;

    constructor(private el: ElementRef,
                private appConfigService: AppConfigService,
                private cesiumContainerService: CesiumContainerService)
    {
        const url = { url: 'https://a.tile.openstreetmap.org' };

        this._defaultOptions = {
            timeline            : false,
            animation           : false,
            homeButton          : true,
            baseLayerPicker     : true,
            fullscreenButton    : true,
            navigationHelpButton: true,
            vrButton            : false,
            geocoder            : true,
            sceneModePicker     : true,
            // imageryProvider     : new Cesium.MapboxImageryProvider({
            //     mapId: 'mapbox.satellite',
            //     accessToken: 'pk.eyJ1IjoiYW5hbHl0aWNhbGdyYXBoaWNzIiwiYSI6ImNpd204Zm4wejAwNzYyeW5uNjYyZmFwdWEifQ.7i-VIZZWX8pd1bTfxIVj9g'
            // })
            imageryProvider : new Cesium.OpenStreetMapImageryProvider({
                url : 'https://a.tile.openstreetmap.org/'
            })
        };
    }

    ngOnInit() {
        const baseUrl = `/${location.pathname.split('/')[1]}/`;

        Cesium.buildModuleUrl.setBaseUrl(baseUrl);
        if (this.defaultViewRectangle) {
            Cesium.Camera.DEFAULT_VIEW_RECTANGLE = 
                Cesium.Rectangle.fromDegrees(this.defaultViewRectangle[0], this.defaultViewRectangle[1],
                                                this.defaultViewRectangle[2], this.defaultViewRectangle[3]);
            Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        }
        this.viewer = new Cesium.Viewer(this.el.nativeElement, Object.assign(this._defaultOptions, this.options));
        this.cesiumContainerService.setViewer('map', this.viewer);
        this.viewer.scene.logarithmicDepthBuffer = false;

        if (!this.appConfigService.getAppConfig("withCatalog") && this.appConfigService.getAppConfig("mapContextId") !== null) {
            const dataUrl = `${this.appConfigService.getEnvConfig("examindServer")}/API/mapcontexts/${this.appConfigService.getAppConfig("mapContextId")}/export`;

            this.cesiumContainerService.getLayersFromMapContextXML(dataUrl)
                .subscribe((layers: ICesiumImageryProviderOptions[]) => {
                    layers.forEach((layer: ICesiumImageryProviderOptions) => {
                        this.cesiumContainerService.setLayer(layer.layers, layer);
                    });
                    this.loadLayers();
                });
        } else {
            this.loadLayers();
        }
    }

    private loadLayers(): void {
        if (this.cesiumContainerService.getLayerListSize() > 0) {
            for (const key in this.cesiumContainerService.getLayerList()) {
                this.cesiumContainerService.addLayer(this.cesiumContainerService.getLayer(key), 'map');
            }
        }
    }

}
